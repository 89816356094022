import { Find } from "./Finder.js";
import { Link } from "react-router-dom";

export function componentConstructor(inputData) {
    const PageContent = inputData.map(elements => {
        switch (elements.type) {
            case 'Title':
                return (
                    <h1 className={elements.type + " p" + elements.align + " w" + elements.width}>{elements.content}</h1>
                )
            case 'Text':
                return (
                    <h2 className={elements.type + " p" + elements.align + " w" + elements.width}>{elements.content}</h2>
                )
            case 'Content':
                return (
                    <h3 className={elements.type + " p" + elements.align + " w" + elements.width}>{elements.content}</h3>
                )
            case 'Detail':
                return (
                    <h4 className={elements.type + " p" + elements.align + " w" + elements.width}>{elements.content}</h4>
                )

            case 'Tab':
                return (
                    <div className={elements.type}>
                        {componentConstructor(elements.content)}
                    </div>
                )
            case 'Space':
                return (
                    <div className={elements.size}></div>
                )
            case 'Separator':
                return (
                    <hr className={elements.type + " w" + elements.width}></hr>
                )

            case 'WideImage':
                return (
                    <div className={elements.type}>
                        <img className={elements.type} src={elements.source} alt={elements.alt}></img>
                        <div className="Inside">
                            <h2 className={elements.type}>{elements.titre}</h2>
                            <h3 className={elements.type}>{elements.content}</h3>
                        </div>
                    </div>
                )
            case 'Image':
                return (
                    <img className={elements.type + " w" + elements.width} src={elements.source} alt={elements.alt}></img>
                )

            case '2-Columns':
                return (
                    <div className={elements.type + " w" + elements.width + " row"} >
                        <div className="col-md-6 col-sm-12 vc">
                            {componentConstructor(elements.col1)}
                        </div>
                        <div className="col-md-6 col-sm-12 vc">
                            {componentConstructor(elements.col2)}
                        </div>
                    </div>
                )
            case '3-Columns':
                return (
                    <div className={elements.type + " w" + elements.width + " row"} >
                        <div className="col-md-4 col-sm-12 vc">
                            {componentConstructor(elements.col1)}
                        </div>
                        <div className="col-md-4 col-sm-12 vc">
                            {componentConstructor(elements.col2)}
                        </div>
                        <div className="col-md-4 col-sm-12 vc">
                            {componentConstructor(elements.col3)}
                        </div>
                    </div>
                )

            case 'Plink':
                elements.content = Find(elements.PKey, elements.CDataType);
                elements.type = elements.Paspect;
                return (
                    <div className={elements.type}>
                        {componentConstructor([elements])}
                    </div>
                )
            case 'Link':
                return (
                    <div className={elements.type + " p" + elements.align}>
                        <Link className={elements.type} to={elements.redirectedPage}>{elements.displayedText}</Link>
                    </div>
                )
            case 'WebLink':
                return (
                    <div className={elements.type}>
                        <a className={elements.type + " p" + elements.align} target="_blank" href={elements.redirectedPage}>{elements.displayedText}</a>
                    </div>
                )
            default:
                return
        }
    })
    return (
        <div>
            {PageContent}
        </div>
    )
}