import React from 'react';
import {NavLink} from "react-router-dom";

class Navbar extends React.Component {
  render() {
    const sendMail = () => {
        const mailto = "mailto:christophe@renouveauthermique.com?subject=Test subject&body=Body content";
        window.open(mailto, 'emailWindow');
      }
    return (
        <header>
            <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-dark">
                <div className="LogoDiv">
                    <a href="/" className="navbar-brand LogoImg">
                        <img src="../Images/logo.jpg" height="100" alt="logo"></img>
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="navbarcollapse">
                    <ul className="d-flex align-items-center navbar-nav justify-content-around w-100">
                        <li><NavLink activeClassName="active" className="inactive nav-link" to="/" exact>Accueil</NavLink></li>
                        <li><NavLink activeClassName="active" className="inactive nav-link" to="/bioclimBatiment">Le bioclimatique et le batiment</NavLink></li>
                        <li className="nav-item dropdown justify-content-center">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Publications
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <NavLink activeClassName="active" className="inactive dropdown-item" to="/livres">Livres</NavLink>
                                <NavLink activeClassName="active" className="inactive dropdown-item" to="/conferences">Conferences</NavLink>
                                <NavLink activeClassName="active" className="inactive dropdown-item" to="/cours">Cours</NavLink>
                                <NavLink activeClassName="active" className="inactive dropdown-item" to="/formations">Formations</NavLink>
                                <NavLink activeClassName="active" className="inactive dropdown-item" to="/articles">Articles</NavLink>
                            </div>
                        </li>
                        <li><NavLink activeClassName="active" className="inactive nav-link" to="/Expertise">Expertise</NavLink></li>
                        <li><NavLink activeClassName="active" className="inactive nav-link" to="/Prestations">Prestations</NavLink></li>
                        <a class="btn btn btn-success"  href='mailto:christophe@renouveauthermique.com?subject=RT - Demande de renseignements'>
                            Me contacter
                        </a>
                    </ul>
                </div>
                <button className="navbar-toggler float-xs-right" type="button" data-toggle="collapse" data-target="#navbarcollapse" aria-controls="navbarcollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
        </header>)
  }
}

export default Navbar;