import Accueil from '../Data/AccueilData';
import BioclimBatiment from '../Data/BioclimbatimentData';
import Expertise from '../Data/ExpertiseData';
import Prestations from '../Data/PrestationsData';

var Data = [
    {
        type : "data",
        data: Accueil
    },{
        type : "data",
        data: BioclimBatiment
    },{
        type : "data",
        data: Expertise
    },{
        type : "data",
        data: Prestations
    },
];

export function Find(key, CDataType) {
    return Treatment(Data, key, CDataType)
}

function Treatment(inputData, key, CDataType) {
    inputData.map(elements => {
        switch (elements.type) {
            case "data":
                Treatment(elements.data, key, CDataType)
                break;
            case "Tab":
                Treatment(elements.content, key, CDataType);
                break;
            case "2-Columns":
                Treatment(elements.col1, key, CDataType);
                Treatment(elements.col2, key, CDataType);
                break;
            case "3-Columns":
                Treatment(elements.col1, key, CDataType);
                Treatment(elements.col2, key, CDataType);
                Treatment(elements.col3, key, CDataType);
                break;
            default:
                if(elements.CKey === key){
                    Data = elements[CDataType];
                }
                break;
        }
    })
    return (Data)
}