export default [{
    type: "Space",
    size: "L"
},
{
    type: "Text",
    align: "center",
    content: "Cours"
},{
    type: "Content",
    align: "center",
    content :"« Bientôt disponible !»"
}, {
    type: "Space",
    size: "S"
},
{
    col2: [
        {
            type: "Image",
            width: "right",
            source: "../Images/image.jpg",
            alt: "essais"
        }
    ],
}
]