export default [{
    type: "Space",
    size: "L"
},
{
    type: "Text",
    align: "center",
    content: "Le Bioclimatisme"
}, {
    type: "Content",
    align: "center",
    content: "« Moins d'énergie fossile pour plus de confort »"
}, {
    type: "Space",
    size: "L"
}, {
    type: "2-Columns",
    width: "standard",
    col1: [
        {
            type: "Text",
            align: "left",
            content: "Du bon sens thermique"
        }, {
            type: "Content",
            align: "justify",
            content: "Tant qu'il fera plus froid la nuit en hiver que le jour en été, le climat nous apportera des différences de potentiel. Le bioclimatisme transforme certaines de ces différences de potentiel pour en faire des énergies exploitables. Un peu de jus de cervelle suffit : c'est le meilleur investissement de votre projet et aussi longtemps que vivra le bâtiment, on y vivra confortablement et on consommera peu d'énergies tarifées pour les cinq usages essentiels (chauffage, ventilation, climatisation, eau chaude sanitaire et leurs accessoires de production)."
        }
    ],
    col2: [
        {
            type: "Image",
            width: "standard",
            source: "../Images/monastere.jpg",
            alt: "essais"
        }
    ],
}, {
    type: "Space",
    size: "L"
}, {
    type: "Text",
    align: "center",
    content: "Si on utilisait le frais des nuits d'été pour se raffraichir le jour ?"
}, {
    type: "Text",
    align: "center",
    content: "Si les murs devenaient des émetteurs de chauffage en hiver ?"
}, {
    type: "Text",
    align: "center",
    content: "Si les ventilations devenaient climatisations ?"
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    width: "standard",
    align: "left",
    content: "Le bon sens thermique c'est de concevoir des structures dont les habitâts consomment peu, ou pas, d'énergies fossiles."
}, {
    type: "Content",
    width: "standard",
    align: "left",
    content: "Chaque terrain subit un climat propre qui lui procure des énergies durables, pérennes, qui ne consument rien de notre planète et ne polluent pas. Un peu de physique et une pincée de sens commun permettent souvent de dompter celles qui peuvent nous être utiles. C'est affaire de bioclimatisme : adapter les structures des bâtiments au climat qui les baigne."
}, {
    type: "Space",
    size: "L"
}, {
    type: "2-Columns",
    width: "standard",
    col1: [
        {
            type: "Text",
            align: "left",
            content: "D = USΔT"
        }, {
            type: "Content",
            align: "justify",
            content: "Les déperditions thermiques d'un bâtiment sont mesurées par une équation célèbre : D = USΔT. Tous les bâtiments perdent de l'énergie. Cette perte d'énergie est proportionelle à l'efficacité de son isolation (U), à sa forme donc à sa surface d'échange (S) et à la différence de température entre ses parois et l'air extérieur (ΔT)."
        }
    ],
    col2: [
        {
            type: "Image",
            width: "standard",
            source: "../Images/bepas.jpg",
            alt: "essais"
        }
    ], 
}, {
    type: "Space",
    size: "M"
},{
    type: "Text",
    align: "left",
    width: "standard",
    content :"Pourquoi le bioclimatisme ? "
},{
    type: "Space",
    size: "S"
},
{
    type: "Content",
    align: "left",
    width: "standard",
    content :"Lorsqu'on a pris soin de s'adapter aux contraintes du terrain et du climat, alors la structure du bâtiment ne souffre pas et il peut durer longtemps. L'option \"je fais fi du climat local\" est une aberration tant du point de vue économique qu'écologique. La forme de la structure, l'emplacement et l'orientation du bâtiment sont déterminants, infiniment déterminants."
},{
    type: "Content",
    align: "left",
    width: "standard",
    content :"Par exemple, le rétrécissement topographique entre les Cévennes et le pré-Alpes créera toujours un vent violent dans la vallée du Rhône : les toits de Provence seront toujours très peu inclinés pour ne pas laisser prise au Mistral. Ou encore : L'irradiation solaire traversera une couche d'atmosphère plus grande en hiver qu'en été, infiniment, parce que cela ne résulte que de le position stellaire de la terre par rapport au soleil. Si la maison est correctement orientée, elle le sera éternellement."
},{
    type: "Content",
    align: "left",
    width: "standard",
    content :"Le confort ne se mesure pas : il contient les conforts thermique, visuel (lumière), phonique (bruits) ; il dépend des volumes, des couleurs, de la salubrité mais aussi de la qualité des matériaux et du professionalisme des ouvriers ; Il repose beaucoup sur la physique de l'enveloppe qui le protège des agressions du climat ou en tire profit. Les Lambda, Rho, C ou Mu des constituants successifs des parois conditionnent in fine l'atmosphère qui règnera dans le bâtiment. Saison après saison, on se sentira mieux dans une maison qui a été conçue pour être confortable, et pour durer !"
}
]