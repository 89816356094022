export default [{
    type: "Space",
    size: "L"
},
    {
        type: "Text",
        align: "center",
        content :"Formations"
    },{
        type: "Content",
        align: "center",
        content :"«Bientôt disponible !»"
    },{
        type: "Space",
        size: "S"
    },
]