export default [
    {
        type: "Space",
        size: "L"
    },{
        type: "Text",
        align: "center",
        width: "standard",
        content :"Prestations"
    },{
        type: "Content",
        align: "center",
        width: "standard",
        content :"Un bâtiment qui tire profit du climat s'allie des puissances récurrentes, infiniment."
    },{
        type: "Space",
        size: "S"
    },{
        type: "Content",
        align: "Left",
        width: "standard",
        content :"Il s’agit de concevoir la structure d’un bâtiment (forme et matériaux) pour qu’elle corresponde à vos besoins ET que celui-ci tire partie des contraintes climatiques du terrain sur lequel il sera construit de façon à ce que la température qui y règne soit stable et confortable. Un bâtiment BEPAS a des besoins très faibles (voire nuls) en énergies fossiles."
    },{
        type: "Space",
        size: "S"
    },{
        type: "Content",
        align: "Left",
        width: "standard",
        content :"Les bâtiments d’avant 1850 étaient généralement le résultat d’une étude bioclimatique poussée. Il y a donc lieu d’éviter certaines erreurs lors de leur rénovation."
    },{
        type: "Space",
        size: "S"
    },{
        type: "Content",
        align: "Left",
        width: "standard",
        content :"La rénovation de bâtiments plus récents fait plus appel à un choix judicieux des isolants utilisés mais certaines rénovations lourdes ou l’ajout de nouveaux volumes peut avantageusement profiter d’une étude bioclimatique."
    },{
        type: "Space",
        size: "S"
    }, {
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content :"Conception"
            },{
                type: "Content",
                align: "left",
                content :"Pour une surface habitable inférieure à 150 m², je peux vous présenter des esquisses de bâtiment bioclimatique adaptées à votre terrain et au climat qui le baigne."
            },{
                type: "Content",
                align: "left",
                content :"A compter de 150 m², je peux travailler à l'esquisse directement avec votre architecte."
            }
                
        ],
        col2: [
            {
                type: "Image",
                width: "full",
                source:"../Images/coupe.jpg",
                alt:"essais"
            }
        ],
    },{
        type: "Space",
        size: "S"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content :"Rénovation"
            },
            {
                type: "Content",
                align: "left",
                content :"Pour une surface habitable inférieure à 150 m², je peux vous présenter des esquisses de bâtiment bioclimatique adaptées à votre terrain et au climat qui le baigne."
            },{
                type: "Content",
                align: "left",
                content :"A compter de 150 m², je peux travailler à l'esquisse directement avec votre architecte."
            },
        ],
        col2: [
            {
                type: "Image",
                width: "full",
                source:"../Images/remise_1.jpg",
                alt:"essais"
            }
        ],
    },{
        type: "Space",
        size: "S"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content :"Interventions/Consultations"
            },
            {
                type: "Content",
                align: "left",
                content :"Vous souhaitez capter plus d'irradiation en hiver, plus de fraîcheur en été ? Vous aimeriez aménager de nouveaux espaces en bioclimatisme, ou optimiser l'existant ?"
            }, {
                type: "Content",
                align: "left",
                content :"Vous avez raison : c'est beaucoup plus agréable pour vous, mais aussi pour la structure de votre bâtiment.*"
            },{
                type: "Detail",
                align: "left",
                content :"* Il y aura par exemple moins de dilatations si les températures dans la maison sont à peu près constantes au long de l'année."
            }
        ],
        col2: [
            {
                type: "Image",
                width: "full",
                source:"../Images/solutions.jpg",
                alt:"essais"
            }
        ]
    },{
        type: "Space",
        size: "S"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content :"Formations"
            },
            {
                type: "Content",
                align: "left",
                content :"Formations personnalisées, cours en ligne ou accompagnement à la résolution d’une problématique spécifique, c’est par ici : LIEN"
            },
        ],
        col2: [
            {
                type: "Image",
                width: "full",
                source:"../Images/remise_2.jpg",
                alt:"essais"
            }
        ],
    },
    {
        type: "Space",
        size: "L"
    },
]