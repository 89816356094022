export default [{
    type: "Space",
    size: "L"
},
    {
        type: "Text",
        align: "center",
        content :"Livres"
    },{
        type: "Content",
        align: "center",
        content :"« Quelques articles »"
    },{
        type: "Space",
        size: "S"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content: "12 solutions bioclimatiques pour l'habitat"
            }, {
                type: "Content",
                align: "justify",
                content: "Dans cette initiation imagée aux principes de la bioclimatique, les auteurs s'appuient sur l'histoire de l'Homme et du climat pour aborder progressivement les principes de la thermique des bâtiments. Les architectes trouveront ici un cours de bioclimatique, les maîtres d'oeuvre et les maîtres d'ouvrage des solutions et des tours de main. Les ingénieurs y puiseront avec plaisir matière à réflexion, les principes dont il est question étant adaptables à toutes les constructions."
            },{
                type: "Link",
                align: "right",
                redirectedPage: "https://www.eyrolles.com/BTP/Livre/12-solutions-bioclimatiques-pour-l-habitat-9782212141023/",
                displayedText: "En savoir plus"
            }
        ],
        col2: [
            {
                type: "Image",
                width: "standard",
                source: "../Images/12solutions.jpg",
                alt: "essais"
            }
        ],
    },{
        type: "Space",
        size: "M"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content: "Comment construire soi-même sa maison bioclimatique"
            }, {
                type: "Content",
                align: "justify",
                content: "Un autoconstructeur sachant déjà faire beaucoup de choses, les auteurs ont pour objectif de l'aider à réussir son projet. Dans cette perspective, ils rappellent quels sont les savoir-faire importants, éclairent certains choix fondamentaux et insistent sur des détails qui conditionnent le succès d'une opération d'autoconstruction tout en veillant à bien respecter les approches de chacun."
            },{
                type: "Link",
                align: "right",
                redirectedPage: "https://www.eyrolles.com/BTP/Livre/comment-construire-soi-meme-sa-maison-bioclimatique-9782212677034/",
                displayedText: "En savoir plus"
            }
        ],
        col2: [
            {
                type: "Image",
                width: "standard",
                source: "../Images/autoconstruire.jpg",
                alt: "essais"
            }
        ],
    },{
        type: "Space",
        size: "M"
    },{
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content: "Les Cataclysmes Fondateurs"
            }, {
                type: "Content",
                align: "justify",
                content: "En quatre cataclysmes, les humains ont presque disparu. Faibles proies, ils vont asservir leur environnement, développant l'agriculture et l'élevage, créant les premières civilisations. Soixante-quinze mille années d'adaptation sont retracées dans ce premier tome : Astéroïdes, éruptions volcaniques, pluies, épisodes de chaleur et de froid, autant d'éléments qui ont déterminé notre évolution de - 75.000 à 3100 BCE. "
            },{
                type: "Link",
                align: "right",
                redirectedPage: "https://www.funnyorphi.com/ebook-store",
                displayedText: "En savoir plus"
            }
        ],
        col2: [
            {
                type: "Image",
                width: "standard",
                source: "../Images/FoundingDisasters.jpg",
                alt: "essais"
            }
        ],
    }
]