import Slider from './Slider.js'
import { componentConstructor } from "./Constructor.js";

import React from 'react';
import data from '../Data/ArticlesData';
// import {Link} from "react-router-dom";

class Articles extends React.Component {
  render() {
    return(
      <div>
        <div class="GeneratedContent">
          {componentConstructor(data)}
        </div>
        <div class="col-md-10 col-sm-12 row-cols-lg-1" >
            <Slider/>
        </div>
        </div>
        )

  }
}

export default Articles;