export default [
    {
        type: "WideImage",
        source:"../Images/accueil.jpg",
        alt:"Alt",
        titre:"Renouveau Thermique",
        content:"Bioclimatisme : utilisons les énergies du climat plutôt que des énergies fossiles pour des bâtiments durables et économes"
    },{
        type: "Space",
        size: "L"
    },
    {
        type: "2-Columns",
        width: "standard",
        col1: [
            {
                type: "Text",
                align: "left",
                content :"La bioclimatique et le bâtiment"
            },{
                type: "Content",
                align: "justify",
                content :"Des techniques d'hier et des matériaux d'aujourd'hui pour optimiser le confort thermique d'un batîment, tout en réduisant sa dépendance aux énergies fossiles, c'est de la bioclimatique"
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/bioclimBatiment",
                displayedText: "En savoir plus"
            },{
                type: "Space",
                size: "S"
            },{
                type: "Text",
                align: "left",
                content :"Prestations"
            },{
                type: "Content",
                align: "justify",
                content :"Retrouvez une liste de mes réalisations, et posez moi des questions quant à vos projets."
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Prestations",
                displayedText: "En savoir plus"
            },{
                type: "Space",
                size: "S"
            },{
                type: "Text",
                align: "left",
                content :"Publications"
            },{
                type: "Content",
                align: "justify",
                content :"Retrouvez mes différents ouvrages, articles, formations et conférences."
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Conferences",
                displayedText: "Conferences"
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Formations",
                displayedText: "Formations"
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Cours",
                displayedText: "Cours"
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Livres",
                displayedText: "Livres"
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Articles",
                displayedText: "Articles"
            },{
                type: "Space",
                size: "S"
            },{
                type: "Text",
                align: "left",
                content :"Expertises"
            },{
                type: "Content",
                align: "justify",
                content :"Découvrez différents cas étudiés et les apports bioclimatiques qui ont été choisis. Il s’agit le plus souvent de chauffer en hiver et de rafraîchir en été."
            },{
                type: "Link",
                align: "right",
                redirectedPage: "/Expertise",
                displayedText: "En savoir plus"
            }
        ],
        col2: [
            {
                type: "Image",
                width: "right",
                source:"../Images/solutions.jpg",
                alt:"essais"
            }
        ],
    }
]