export default [{
    type: "Space",
    size: "L"
},
{
    type: "Text",
    align: "center",
    width: "standard",
    content: "Expertise"
}, {
    type: "Content",
    align: "center",
    width: "standard",
    content: "« Utiliser des principes anciens et les conjuguer avec nos matériaux modernes ne constitue pas une novation, c’est un re-nouveau : un renouveau thermique.»"
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    align: "center",
    width: "standard",
    content: "Il s’agit de tirer parti de la connaissance des contraintes climatiques locales et des solutions que des millénaires de constructions ont mises au point ; il s’agit aussi de les mettre en œuvre avec nos matériaux contemporains dans le cadre de nos règlementations actuelles."
}, {
    type: "Space",
    size: "S"
}, {
    type: "Image",
    width: "standard",
    source: "../Images/hiver.jpg",
    alt: "essais"
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    align: "justify",
    width: "standard",
    content: "Dans l'arrière-pays méditerranéen, on a toujours utilisé la grande différence de températures entre les jours et les nuits pour chauffer les intérieurs, la nuit, grâce à l'irradiation emmagasinée par les parois durant la journée. Selon les caractéristiques de la terre du chantier, les parois avaient la bonne épaisseur pour qu'au plus froid de la nuit (4h du matin), la face interne des murs irradie la chaleur perçue par la face externe dudit mur, au plus chaud de la journée (4h de l'après-midi)."
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    align: "justify",
    width: "standard",
    content: "Ce qui était des connaissances empiriques se calcule très bien, aujourd'hui, puisque l'on connait la densité, la chaleur massique et le Lambda de pratiquement tous les matériaux utilisés dans la construction."
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    align: "justify",
    width: "standard",
    content: "Vers 2000 avant JC, un changement climatique d'une grande puissance a refroidi l'Egypte mais les égyptiens n'abattirent pas plus d'arbres pour chauffer leurs logements : ils installèrent des placards au Nord des temples et enduisirent les toits de leurs maisons d'ocre rouge. Aujourd'hui encore, si l'on place une rangée de placards au Nord d'une habitation, elle crée une pièce tampon (d'autant efficace qu'ils sont pleins de tissus ou de textiles) où la température sera tempérée, intermédiaire entre celle de la paroi Nord et celle du dressing."
}, {
    type: "Space",
    size: "S"
}, {
    type: "Content",
    align: "justify",
    width: "standard",
    content: "Avant 1850, on utilisait le stockage des grains dans le grenier pour se protéger du froid d'hiver et l'ombre des arbres pour s'abriter de l'irradiation en été : on ne connaissait pas encore les isolants. On utilisait les ombrières, les trappes, la couleur ou la densité des matériaux pour obtenir un cofort thermique optimal."
},
]