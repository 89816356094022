import './App.css';
import {Route, BrowserRouter as Router} from "react-router-dom";
import Navbar from './Components/NavBar';

import Acceuil from './Components/Accueil';
import Bioclimatisme from './Components/BioclimBatiment';
import Articles from './Components/Articles';
import Cours from './Components/Cours';
import Livres from './Components/Livres';
import Formations from './Components/Formations';
import Conferences from './Components/Conferences';
import Expertise from './Components/Expertise'
import Prestations from './Components/Prestations'
import Contact from './Components/Contact'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <header className="App-header">
          <Route path="/" exact component={Acceuil} />
          <Route path="/bioclimBatiment" component={Bioclimatisme} />
          <Route path="/articles" component={Articles} />
          <Route path="/cours" component={Cours} />
          <Route path="/formations" component={Formations} />
          <Route path="/conferences" component={Conferences} />
          <Route path="/livres" component={Livres} />
          <Route path="/Expertise" component={Expertise} />
          <Route path="/Prestations" component={Prestations} />
          <Route path="/Contact" component={Contact} />
        </header>
      </div>
    </Router>
  );
}

export default App;

