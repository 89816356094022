import React from 'react';
import data from '../Data/ExpertiseData';
import { componentConstructor } from "./Constructor.js";

class Expertise extends React.Component {
	render() {
		return (
			<div class="GeneratedContent">
				{componentConstructor(data)}
			</div>
		)
	}
}

export default Expertise;