export default [{
    type: "Space",
    size: "L"
},
    {
        type: "Text",
        align: "center",
        content :"Articles"
    },{
        type: "Content",
        align: "center",
        content :"« Quelques articles »"
    },{
        type: "Space",
        size: "S"
    },
]