import React from 'react';
import data from '../Data/PrestationsData';
import { componentConstructor } from "./Constructor.js";

class Prestations extends React.Component {
    render() {
        return (
            <div class="GeneratedContent">
                {componentConstructor(data)}
            </div>
        )
    }
}

export default Prestations;